@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7fb 0% 0% no-repeat padding-box;
}

code {
  font-family: "Poppins", sans-serif;
}

a {
  cursor: pointer;
}